<template>
    <div class="portal-body__pgsolutions">
        <h1 v-html="tmd('dbyloi_generic_portal_header_pgs')"></h1>
        <p v-html="tmd('dbyloi_portal_pgs_body_1')"></p>
        <p v-html="tmd('dbyloi_portal_pgs_body_2')"></p>
        <p v-html="tmd('dbyloi_portal_pgs_body_3')"></p>
        <p v-html="tmd('dbyloi_portal_pgs_body_4')"></p>
        <p v-html="tmd('dbyloi_portal_pgs_body_5')"></p>
        <p v-html="tmd('dbyloi_portal_pgs_body_6')"></p>
        <!-- <p v-html="tmd('dbyloi_portal_pgs_body_7')"></p> -->
        <p>
PGSolutions Group CIC is an independent, not for profit, Community Interest
Company based in the UK. The objectives of the company are to carry on
activities which benefit the community affected by gambling problems.</p>
        <p>In particular to:</p>
            <ul>
                <li>Deliver Virtual Mentoring, Safer Gambling Programmes, Self Help
        Tools - accessible across multiple networks and social media platforms using the
        knowledge and understanding of those with lived-experiences combined with
        digital expertise.</li>
                <li>Develop more virtual communication tools, in text, audio, and
        video using technology with the capacity to adapt and change inline with a fast
        moving gambling environment, to educate and deliver safer gambling strategies.</li>
                <li>Deliver Prevention and Early Intervention techniques, as part of an education
        programme of Safer Gambling Strategies, to minimise and reduce the impact of
        problem gambling.</li>
                <li>Provide business and a wide range of organisations with
        training and/or signposting opportunities to facilitate the adoption of a Safer
        Gambling Programme for their customers.</li>
            </ul>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
    }
})
export default class PortalPGSolutions extends Vue {

}
</script>