<template>
    <div class="leader-videos">
        <a id="getting-started" class="anchor" />
        <a id="needing-help" class="anchor" />
        <div class="leader-videos__current">
            <div class="leader-videos__hero-wrapper">
                <div class="leader-videos__video-wrapper">
                    <!-- <iframe ref="player" :src="`https://player.vimeo.com/video/${curId}?title=0&byline=0&portrait=0&autoplay=${autoplay}`" class="leader-videos__video-frame" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe> -->
                    <div ref="player" id="video-player" />
                </div>
            </div>
        </div>
        <div class="leader-videos__thumbnails">
            <div v-for="(thumb, i) in thumbs" :key="i" class="leader-videos__thumb-container" :class="{'leader-videos__thumb-container--active': index==i}">
                <a class="leader-videos__thumb-link" href="#" @click.prevent="showVideo(i)">
                    <img class="leader-videos__thumb-image" :src="getThumbnail(thumb.image)" />
                    <span class="leader-videos__thumb-status">
                        <span v-if="index==i" class="leader-videos__thumb-status-now-playing">Now Playing...</span>
                        <span v-if="index!=i" class="leader-videos__thumb-status-play"><img v-svg-inline src="@/assets/app-images/svgs/play.svg" /></span>
                    </span>
                </a>
                <p><span class="circle" :style="{'background-color': thumb.color}"><span class="num">{{ thumb.num }}</span></span> {{ thumb.title }}</p>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Player from '@vimeo/player';

@Options({
    props: {
    }
})
export default class LeaderVideos extends Vue {

    public videos = [
        622810399,
        623321330,
        622810583,
    ];

    public thumbs = [
        { num: '0', image: 'introducing-ed', title: "Introducing Ed", color: "#6e7273" },
        { num: '1', image: 'getting-started', title: "Getting Started", color: "#22C3FF"  },
        { num: '2', image: 'needing-help', title: "Needing Help", color: "#EB3232"  },
    ]

    public index = 0;
    public player?: Player;

    mounted()
    {
        this.player = new Player('video-player', {
            id: this.videos[0],
            byline: false,
            portrait: false,
        });
        this.player.on('ended', this.onEnded);
        this.player.on('play', () => {
            this.$emitter.emit('globalPlay', 'LeaderVideos');
        });

        this.$emitter.on('globalPlay', this.handleGlobalPlay);
    }

    public beforeUnmount()
    {
        this.$emitter.off('globalPlay', this.handleGlobalPlay);
    }

    private handleGlobalPlay(target: string)
    {
        // console.log(`handleGlobalPlay ${target}`);
        if(target !== 'LeaderVideos')
        {
            this.player?.pause();
        }
    }

    // get curId(): string
    // {
    //     return this.videos[this.index];
    // }

    getThumbnail(name: string)
    {
        return require(`@/assets/${name}.jpg`)
    }

    private onEnded()
    {
        if(this.index < this.videos.length-1)
        {
            this.showVideo(this.index+1);
        }
    }

    public async showVideo(i: number)
    {
        this.index = Math.max(0, Math.min(this.videos.length-1, i));

        if(this.player)
        {
            await this.player.loadVideo(this.videos[this.index]);
            this.player.play();
        }
    }

}
</script>