<template>
    <div class="portal-link">
        <img class="portal-link__logo" v-svg-inline src="@/assets/app-images/svgs/dbyloi-logo.svg" />
        <p class="portal-link__intro">To know more about <strong>Don’t bet YOUR LIFE on it…</strong> and to adopt the <strong>DBYLOI</strong> initiative please visit the Portal: </p>
        <router-link to="portal" class="dont-break-out portal-link__link">dontbetyourlifeonit.co.uk<wbr>/<wbr>portal</router-link>
        <p class="portal-link__contact-us">or Contact Us</p>
        <div class="portal-link__contact-button">
            <CTAButton icon="scrub-forward"><a href="mailto:pgsolutions@dbyloi.co.uk?subject=Don't Bet Your Life On It">Email</a></CTAButton>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

import CTAButton from '@/components/common/CTAButton.vue';

@Options({
    props: {
    },
    components: {
        CTAButton
    }
})
export default class PortalLink extends Vue {

}
</script>