<template>
    <div class="footer">

        <div class="footer__copy">
            <p>Community Interest Company no. 13518501</p>
            <p>Copyright 2021 | <router-link target="_blank" :to="{ name: 'privacy' }">PGSolutions Group Privacy and Cookie Policy</router-link></p>
        </div>

    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

import Socials from "@/components/Socials.vue";

@Options({
    props: {
    },
    components: {
        Socials
    }
})
export default class Footer extends Vue {

}
</script>