<template>
    <div class="privacy__wrapper">
        <div class="privacy__header">
            <router-link :to="{ name: 'home' }"><img class="privacy__header-logo" v-svg-inline src="@/assets/app-images/svgs/dbyloi-logo.svg" /></router-link>
        </div>
        <div class="privacy__main">
            <h1>Background Statement</h1>
            <p v-html="tmd('dbyloi_privacy_main_body_1')" />
            <!-- <p v-html="tmd('dbyloi_privacy_main_body_2')" /> -->

            <address>
                <ol class="privacy__address">
                    <li v-html="tmd('dbyloi_privacy_main_address_1')" />
                    <li v-html="tmd('dbyloi_privacy_main_address_2')" />
                    <li v-html="tmd('dbyloi_privacy_main_address_3')" />
                    <li v-html="tmd('dbyloi_privacy_main_address_4')" />
                    <li v-html="tmd('dbyloi_privacy_main_address_5')" />
                    <!-- <li v-html="tmd('dbyloi_privacy_main_address_6')" /> -->
                </ol>
            </address>

            <p>To contact PGSolutions please email: <a href="mailto:pgsolutions@dbyloi.co.uk">pgsolutions@dbyloi.co.uk</a></p>

            <p v-html="tmd('dbyloi_privacy_main_body_3')" />
            <p v-html="tmd('dbyloi_privacy_main_body_4')" />
            <p v-html="tmd('dbyloi_privacy_main_body_5')" />
            <p v-html="tmd('dbyloi_privacy_main_body_6')" />
            <p v-html="tmd('dbyloi_privacy_main_body_7')" />
            <p v-html="tmd('dbyloi_privacy_main_body_8')" />
            <p v-html="tmd('dbyloi_privacy_main_body_9')" />
            <p v-html="tmd('dbyloi_privacy_main_body_10')" />
            <p v-html="tmd('dbyloi_privacy_main_body_11')" />
            <p v-html="tmd('dbyloi_privacy_main_body_12')" />

            <p class="strong" v-html="tmd('dbyloi_privacy_main_body_13')" />
            <p class="strong" v-html="tmd('dbyloi_privacy_main_body_14')" />
            <p v-html="tmd('dbyloi_privacy_main_body_15')" />
            <p v-html="tmd('dbyloi_privacy_main_body_16')" />
            <p v-html="tmd('dbyloi_privacy_main_body_17')" />

            <p class="strong" v-html="tmd('dbyloi_privacy_main_body_18')" />
            <p v-html="tmd('dbyloi_privacy_main_body_19')" />
            <p v-html="tmd('dbyloi_privacy_main_body_20')" />
            <p v-html="tmd('dbyloi_privacy_main_body_21')" />
            <p v-html="tmd('dbyloi_privacy_main_body_22')" />

            <p class="strong" v-html="tmd('dbyloi_privacy_main_body_23')" />
            <p v-html="tmd('dbyloi_privacy_main_body_24')" />
            <p v-html="tmd('dbyloi_privacy_main_body_25')" />
            <p v-html="tmd('dbyloi_privacy_main_body_26')" />

            <p class="strong" v-html="tmd('dbyloi_privacy_main_body_27')" />

            <p v-html="tmd('dbyloi_privacy_main_body_28')" />
            <p v-html="tmd('dbyloi_privacy_main_body_29')" />
            <p v-html="tmd('dbyloi_privacy_main_body_30')" />
            <p v-html="tmd('dbyloi_privacy_main_body_31')" />

            <br />
            <br />
            <br />
       </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';


@Options({
  props: {
    msg: String
  },
  components: {
  }
})
export default class PrivacyPolicy extends Vue {

}
</script>