<template>
    <div class="player-led">
        <h3 class="player-led__header">The <strong>First Player-Led</strong> Safer Gambling Initiative</h3>
        <p class="player-led__body">‘Raising Standards of Player Protection to reduce the <strong>HARMS</strong> caused by gambling’</p>

        <slot></slot>

        <p class="player-led__produced-by">Designed and Produced by<br /><strong>PGSolutions Group</strong></p>
        <p style="font-family: 'Raleway', 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 2em;">We are an independent, not-for-profit Community Interest Company
            based in the UK working with lived-experience to create content
            and innovative digital technologies to deliver it.</p>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
    }
})
export default class PlayerLed extends Vue {

}
</script>